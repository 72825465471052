import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import className from 'classnames'
import parse from 'html-react-parser'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import cn from 'classnames'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import * as style from './main-primary.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'

const MainPrimary = ({
  pageData: {
    showcase,
    mainHeading,
    title,
    mainDescription,
    mainHeaderButton,
    headerBusinesses,
  },
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeAccordion, setActiveAccordion] = useState(0)

  const activeTabAction = (n) => {
    setActiveTab(n)
  }

  const activeAccordionAction = (n) => {
    setActiveAccordion(n)
  }

  return (
    <main>
      <section className={style.main}>
        <div
          style={{
            backgroundImage: `url('${
              // eslint-disable-next-line react/prop-types
              showcase.image &&
              // eslint-disable-next-line react/prop-types
              showcase.image.localFile.childImageSharp.resize.src
            }')`,
          }}
          className={style.wrap}
        >
          <div className={className(style.container, 'container')}>
            <div className={style.pic} />

            <div className={style.heading}>
              <div className={style.content}>
                <p className={style.capture}>{mainHeading}</p>
                <h1 className={style.title}>{parse(title)}</h1>
                <p className={style.description}>{mainDescription}</p>
                <ButtonPrimary buttonData={mainHeaderButton} />
                {headerBusinesses.map((item, index) => (
                  <h2
                    key={keyGenerator(index, item.title)}
                    className="hidden hidden--dark"
                  >
                    {item.title}
                  </h2>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={style.switcher}>
          <div className="container">
            <div className={style.wrapper}>
              {headerBusinesses.map((item, index) => {
                const { businessButton } = item
                const url = businessButton.useCustomUrl
                  ? businessButton.urlCustom
                  : businessButton.url.uri
                return (
                  <div
                    tabIndex={0}
                    role="button"
                    key={keyGenerator(index, item.title)}
                    className={className(
                      style.slide,
                      activeTab === index && style.activeTab
                    )}
                    onClick={() => activeTabAction(index)}
                    onKeyDown={() => activeTabAction(index)}
                    onMouseEnter={() => activeTabAction(index)}
                  >
                    <Link to={url}>
                      <p className={style.slideTitle}>{item.title}</p>
                    </Link>

                    {item.image && (
                      <Link
                        to={url}
                        style={{
                          height:
                            item.image.localFile.childImageSharp.gatsbyImageData
                              .height,
                        }}
                        className={style.card}
                      >
                        <div className={style.img}>
                          <GatsbyImage
                            width={300}
                            height={300}
                            title={item.image.altText}
                            image={
                              item.image.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            alt={item.image.altText}
                          />
                        </div>
                        <div className={style.bg}>
                          <p className={style.slideDescription}>
                            {item.description}
                          </p>
                          <ButtonPrimary
                            btnClass={style.slideLink}
                            buttonData={item.businessButton}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div id="main-accordion" className={style.accordion}>
          {headerBusinesses.map((item, index) => (
            <div
              aria-label="next slide"
              role="button"
              tabIndex={0}
              key={keyGenerator(index, item.title)}
              className={className(
                style.accordionItem,
                index === activeAccordion && style.accordionShow
              )}
              onClick={() => activeAccordionAction(index)}
              onMouseEnter={() => activeAccordionAction(index)}
              onKeyDown={() => activeAccordionAction(index)}
            >
              <header className={style.accordionHeader}>
                <span />
              </header>
              <div
                className={cn(style.accordionContent, !index && style.first)}
              >
                <p
                  className={className(
                    style.accordionTitle,
                    'text-color--main'
                  )}
                >
                  {item.title}
                </p>
                <p className={style.accordionDescription}>{item.description}</p>
                <ButtonPrimary isSimpleLink buttonData={item.businessButton} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  )
}
MainPrimary.propTypes = {
  pageData: PropTypes.shape({
    mainHeading: PropTypes.string,
    title: PropTypes.string,
    mainDescription: PropTypes.string,
    mainHeaderButton: PropTypes.shape({
      query: PropTypes.string,
      text: PropTypes.string,
    }),
    headerBusinesses: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}
export default MainPrimary
