import PropTypes from 'prop-types'
import * as React from 'react'
import cn from 'classnames'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import keyGenerator from '../../../utils/keyGenerator'
import * as style from './hover-text-component.module.scss'
import ButtonPrimary from '../../common/buttons/ButtonPrimary'

const LeftBorderTextComponent = ({
  index,
  title,
  description,
  url,
  onHover,
  onClick,
  activeItem,
  isArrow,
  isLight,
  isPrimary,
  button,
  size,
  descriptionClass,
}) => {
  const hoverTextItemActiveStyle = cn(
    style.hoverTextItemActive,
    isLight && style.hoverTextItemActiveLight,
    isPrimary && style.hoverTextItemActivePrimary,
    !description && style.hoverTextItemActiveSmall
  )
  const buttonData = {
    ...button,
  }

  const TextContent = ({ children }) => (
    <>
      {url ? (
        <AnchorLink className={style.hoverTextLink} to={url}>
          {children}
        </AnchorLink>
      ) : (
        <div className={style.hoverTextLink}>{children}</div>
      )}
    </>
  )
  const HoverTextWrap = ({ children }) => (
    <>
      {activeItem !== null ? (
        <div
          key={keyGenerator(index, title)}
          onMouseEnter={onHover && onHover(index)}
          onClick={onClick && onClick(index)}
          className={cn(
            style.hoverTextItem,
            onClick && style.pointer,
            (!description || size === 'small') && style.hoverTextItemSmall,
            isArrow && style.isArrow,
            activeItem === index && hoverTextItemActiveStyle
          )}
        >
          <div
            role="button"
            tabIndex={0}
            className={cn(
              style.hoverTextTitleMain,
              isLight && style.hoverTextTitleMainLight
            )}
          >
            {!description || size === 'small' ? (
              <p
                className={cn(
                  'headline-h5',
                  !description && 'text-24-link-bold'
                )}
              >
                {title}
              </p>
            ) : (
              <p className="headline-h5">{title}</p>
            )}
          </div>
          {children}
        </div>
      ) : (
        <div
          key={keyGenerator(index, title)}
          className={cn(
            style.hoverTextItem,
            isArrow && style.isArrow,
            style.hoverTextItemActive
          )}
        >
          {children}
        </div>
      )}
    </>
  )
  return (
    <HoverTextWrap>
      <TextContent url={url}>
        <div className={style.hoverTextTitle}>
          <p
            className={cn(
              style.hoverTextHeading,
              size === 'small' && style.hoverTextHeadingSmall
            )}
          >
            {title}
          </p>
        </div>
        <p
          className={cn(
            style.hoverTextText,
            isLight && style.hoverTextTextLight,
            descriptionClass
          )}
        >
          {description}
        </p>
        {button && (
          <ButtonPrimary
            buttonData={buttonData}
            arrowDirection={button.arrowDirection}
            btnClass={style.button}
            isLight={button.isLight}
            onClick={button.onClick}
          />
        )}
      </TextContent>
    </HoverTextWrap>
  )
}
LeftBorderTextComponent.defaultProps = {
  url: '',
  isArrow: false,
  isLight: false,
  activeItem: null,
  size: 'medium',
}
LeftBorderTextComponent.propTypes = {
  url: PropTypes.string,
  isArrow: PropTypes.bool,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  activeItem: PropTypes.number,
  isLight: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
}
export default LeftBorderTextComponent
