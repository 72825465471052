import React from 'react'
import classnames from 'classnames'
import * as style from './modal-video.module.scss'

const ModalVideo = ({ videoData = '' }) => (
  <div className={classnames(style.videoYoutube, 'video')}>
    <iframe
      src={videoData}
      title="videoTitle"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default ModalVideo
