// extracted by mini-css-extract-plugin
export var accordion = "main-products-module--accordion--wdcBB";
export var animateCircle = "main-products-module--animate-circle--AU6Vq";
export var bounce = "main-products-module--bounce--57Vpd";
export var button = "main-products-module--button--YENGO";
export var first = "main-products-module--first--yRZAs";
export var firstText = "main-products-module--firstText--u2vah";
export var ldsRing = "main-products-module--lds-ring--ZoaB4";
export var products = "main-products-module--products--oX80+";
export var products__first = "main-products-module--products__first--UQHrD";
export var second = "main-products-module--second--zTypk";
export var wrapper = "main-products-module--wrapper--ecHFn";