import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import * as style from './main-values.module.scss'
import Section from '../../../common/SectionComponent/Section'

const MainValues = ({ pageData }) => (
  <Section title={pageData.title} newClass={style.values} isLight>
    <div>
      <Fade bottom>
        <p className="text-color--secondary">{pageData.description}</p>
      </Fade>
    </div>
  </Section>
)

MainValues.propTypes = {
  pageData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default MainValues
