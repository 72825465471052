import * as React from 'react'
import className from 'classnames'
import { useState } from 'react'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import PropTypes from 'prop-types'
import Section from '../../../common/SectionComponent/Section'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import * as style from './main-products.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import LeftBorderTextComponent from '../../../reusable/LeftBorderTextComponent/LeftBorderTextComponent'
// markup
const MainProducts = ({
  pageData: {
    companyHeader,
    companyBtn,
    experienceHeader,
    experienceItems,
    experienceButton,
  },
}) => {
  const [activeItem, setActiveItem] = useState(0)
  const activeItemToggle = (n) => () => {
    setActiveItem(n)
  }
  return (
    <div className={style.products}>
      <div className={className(style.wrapper, 'container')}>
        <div className={style.first}>
          <Section title={companyHeader.title}>
            <Slide bottom>
              <p
                className={className(
                  'text-color__gray--on-dark',
                  style.firstText
                )}
              >
                {companyHeader.description}
              </p>
            </Slide>
            <ButtonPrimary btnClass={style.button} buttonData={companyBtn} />
          </Section>
        </div>
        <div className={style.second}>
          <Section title={experienceHeader.title}>
            <Slide bottom>
              <p className="text-color__gray--on-dark">
                {experienceHeader.description}
              </p>
            </Slide>

            <div className={style.accordion}>
              {experienceItems.map((elem, index) => {
                const { addQuery, useCustomUrl, query, urlCustom, url } =
                  elem.button
                // eslint-disable-next-line no-nested-ternary
                const plateUrl = useCustomUrl
                  ? urlCustom
                  : addQuery
                  ? url.uri + query
                  : url.uri
                return (
                  <div key={keyGenerator(index, elem.title)}>
                    <Fade bottom>
                      <LeftBorderTextComponent
                        isLight
                        isPrimary
                        isArrow
                        title={elem.title}
                        description={elem.description}
                        index={index}
                        url={plateUrl}
                        onHover={activeItemToggle}
                        onClick={activeItemToggle}
                        activeItem={activeItem}
                      />
                    </Fade>
                  </div>
                )
              })}
            </div>
            <ButtonPrimary buttonData={experienceButton} />
          </Section>
        </div>
      </div>
    </div>
  )
}
MainProducts.propTypes = {
  pageData: PropTypes.shape({
    companyHeader: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    companyBtn: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.shape({
        link: PropTypes.string,
      }),
    }),
    experienceHeader: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    experienceItems: PropTypes.arrayOf(PropTypes.object),
    experienceButton: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.shape({
        uri: PropTypes.string,
      }),
    }),
  }).isRequired,
}
export default MainProducts
