// extracted by mini-css-extract-plugin
export var animateCircle = "hover-text-component-module--animate-circle--OgmcV";
export var bounce = "hover-text-component-module--bounce--knSE0";
export var button = "hover-text-component-module--button--wdc3d";
export var hoverText = "hover-text-component-module--hoverText--E5UD6";
export var hoverTextHeading = "hover-text-component-module--hoverTextHeading--he0tU";
export var hoverTextItem = "hover-text-component-module--hoverTextItem--o36qG";
export var hoverTextItemActive = "hover-text-component-module--hoverTextItemActive--J1+UW";
export var hoverTextItemActiveLight = "hover-text-component-module--hoverTextItemActiveLight--RnxBG";
export var hoverTextItemActivePrimary = "hover-text-component-module--hoverTextItemActivePrimary--UpYa-";
export var hoverTextItemActiveSmall = "hover-text-component-module--hoverTextItemActiveSmall--3e1jA";
export var hoverTextLink = "hover-text-component-module--hoverTextLink--pAQba";
export var hoverTextText = "hover-text-component-module--hoverTextText--bm2GI";
export var hoverTextTextLight = "hover-text-component-module--hoverTextTextLight--FMNZ0";
export var hoverTextTitle = "hover-text-component-module--hoverTextTitle--W+l26";
export var hoverTextTitleMain = "hover-text-component-module--hoverTextTitleMain--M40Qu";
export var hoverTextTitleMainLight = "hover-text-component-module--hoverTextTitleMainLight--jjjsW";
export var isArrow = "hover-text-component-module--isArrow--z4Crd";
export var ldsRing = "hover-text-component-module--lds-ring--TjCvP";
export var pointer = "hover-text-component-module--pointer--gEzAn";