import * as React from 'react'
import className from 'classnames'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import * as style from './main-development.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const MainDevelopment = ({ pageData }) => {
  const [activeItem, serActiveItem] = useState(0)
  const activeItemToggle = (n) => {
    serActiveItem(n)
  }
  const ItemData = ({ data, isActive }) => {
    const {
      title,
      description,
      iconDescription,
      icons,
      showButton,
      offeringsButton,
    } = data
    return (
      <div className={className(style.body, isActive && style.itemDataActive)}>
        <p className={style.bodyHeading}>{title}</p>
        <p className={style.descriptionText}>{description}</p>
        {iconDescription && <p className={style.heading}>{iconDescription}</p>}
        <div className={style.icons}>
          {icons &&
            icons.map(
              (elem, i) =>
                elem.icon &&
                elem.title && (
                  <div key={keyGenerator(i, elem.title)} className={style.icon}>
                    <img
                      title={elem.title}
                      loading="lazy"
                      width="50"
                      height="50"
                      src={elem.icon.sourceUrl}
                      alt={elem.title}
                    />
                    <p>{elem.title}</p>
                  </div>
                )
            )}
        </div>
        {showButton && (
          <ButtonPrimary buttonData={offeringsButton} isSimpleLink isLight />
        )}
      </div>
    )
  }
  return (
    <Section isLight title={pageData.offeringsHeader.title}>
      <div className="section-component__content">
        <Fade bottom>
          <p className={style.description}>
            {pageData.offeringsHeader.description}
          </p>
        </Fade>
        <div className={style.accordion}>
          <div className={style.list}>
            {pageData.offeringItems.map((item, index) => (
              <Fade bottom>
                <div>
                  <div
                    key={keyGenerator(index, item.title)}
                    className={className(
                      style.item,
                      activeItem === index && style.itemActive
                    )}
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => activeItemToggle(index)}
                      onKeyDown={() => activeItemToggle(index)}
                      className={style.title}
                    >
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                  <div className={style.mobileItemData}>
                    {activeItem === index && <ItemData isActive data={item} />}
                  </div>
                </div>
              </Fade>
            ))}
          </div>
          <div className={style.itemData}>
            {pageData.offeringItems.map(
              (item, index) => activeItem === index && <ItemData data={item} />
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

MainDevelopment.propTypes = {
  pageData: PropTypes.shape({
    offeringsHeader: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    offeringItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}
export default MainDevelopment
