// extracted by mini-css-extract-plugin
export var ampSection__title = "main-clients-module--amp-section__title--uvLtG";
export var animateCircle = "main-clients-module--animate-circle--zS7si";
export var blockButton = "main-clients-module--blockButton--Qy9Um";
export var blockButtonNext = "main-clients-module--blockButtonNext--4Jwrx";
export var blockButtonPrev = "main-clients-module--blockButtonPrev--5D766";
export var blockCountry = "main-clients-module--blockCountry--mjJ1c";
export var blockImages = "main-clients-module--blockImages--sVosZ";
export var blockImagesActive = "main-clients-module--blockImagesActive--mNYBt";
export var blockInfo = "main-clients-module--blockInfo--QOCq8";
export var blockInfoLight = "main-clients-module--blockInfoLight--TiJWK";
export var blockItem = "main-clients-module--blockItem--jpGKe";
export var blockItemActive = "main-clients-module--blockItemActive--53cde";
export var blockName = "main-clients-module--blockName--zT3dS";
export var blockNav = "main-clients-module--blockNav--tIMN3";
export var blockPortrait = "main-clients-module--blockPortrait--h3U67";
export var blockText = "main-clients-module--blockText--a48Vm";
export var blockTitle = "main-clients-module--blockTitle--rg2on";
export var bounce = "main-clients-module--bounce--Qx4sN";
export var description = "main-clients-module--description--PGdX6";
export var ldsRing = "main-clients-module--lds-ring--u7fNZ";
export var mainClients__blockName = "main-clients-module--main-clients__block-name--vQclh";
export var noVideo = "main-clients-module--noVideo--d210M";
export var preview = "main-clients-module--preview--zO7zR";
export var video = "main-clients-module--video--HLfg0";
export var videoBg = "main-clients-module--videoBg--MJY0D";
export var videoButton = "main-clients-module--videoButton--3TpG+";
export var videoCoverImg = "main-clients-module--videoCoverImg--T-uZr";
export var videoInfo = "main-clients-module--videoInfo--Kphrd";
export var videoPreview = "main-clients-module--videoPreview--6AoAb";
export var videoText = "main-clients-module--videoText--9hNbP";
export var wrapper = "main-clients-module--wrapper--vmQ9n";