// extracted by mini-css-extract-plugin
export var accordion = "main-primary-module--accordion--VS68S";
export var accordionContent = "main-primary-module--accordionContent--rRNfe";
export var accordionDescription = "main-primary-module--accordionDescription--yj5vl";
export var accordionHeader = "main-primary-module--accordionHeader--oHLoa";
export var accordionItem = "main-primary-module--accordionItem--Qab-z";
export var accordionLink = "main-primary-module--accordionLink--c8sDk";
export var accordionShow = "main-primary-module--accordionShow--DgLKn";
export var accordionTitle = "main-primary-module--accordionTitle--K4-na";
export var activeTab = "main-primary-module--activeTab--fk8Mk";
export var animateCircle = "main-primary-module--animate-circle--Upcq4";
export var bg = "main-primary-module--bg--bRJLT";
export var bounce = "main-primary-module--bounce--vnWOv";
export var capture = "main-primary-module--capture--TadRc";
export var card = "main-primary-module--card--IJVB2";
export var container = "main-primary-module--container--jgDOu";
export var content = "main-primary-module--content--gN+DO";
export var description = "main-primary-module--description--q+Yu6";
export var first = "main-primary-module--first--88Mi5";
export var heading = "main-primary-module--heading--xh1J0";
export var img = "main-primary-module--img--GERD6";
export var ldsRing = "main-primary-module--lds-ring--VIA+I";
export var links = "main-primary-module--links--Fp+S-";
export var main = "main-primary-module--main--wEP3E";
export var pic = "main-primary-module--pic--EA9Ah";
export var slideDescription = "main-primary-module--slideDescription--tAB06";
export var slideLink = "main-primary-module--slideLink--OQVwY";
export var slideTitle = "main-primary-module--slideTitle--RLYMe";
export var switcher = "main-primary-module--switcher--1YwxE";
export var title = "main-primary-module--title--YGkLI";
export var wrap = "main-primary-module--wrap--+C+Fv";
export var wrapper = "main-primary-module--wrapper--xje+V";