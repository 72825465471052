import React, { useRef, useState } from 'react'

import classnames from 'classnames'
import Fade from 'react-reveal/Fade'
import { StaticImage } from 'gatsby-plugin-image'
import * as style from './main-clients.module.scss'
import Section from '../../../common/SectionComponent/Section'
import Modal from '../../../common/Modal/Modal'
import ModalVideo from '../../../common/Modal/ModalVideo/ModalVideo'
import keyGenerator from '../../../../utils/keyGenerator'

const MainClients = ({ video, heading, clientItems, isLight }) => {
  const [activeItem, setActiveItem] = useState(0)
  const [clients, setClients] = useState(clientItems)

  const activeItemToggle = (index) => {
    const arr = Array.from(clients)
    const active = arr.splice(index, 1)[0]
    arr.unshift(active)
    setClients(arr)
  }
  const nextSlide = () => {
    const activeSlide = activeItem === clients.length - 1 ? 0 : activeItem + 1
    setActiveItem(activeSlide)
  }
  const prevSlide = () => {
    const activeSlide = activeItem === 0 ? clients.length - 1 : activeItem - 1
    setActiveItem(activeSlide)
  }

  const modalRef = useRef()

  return (
    <Section
      isLight={isLight}
      title={heading.title}
      class="clients amp-section"
    >
      <Fade bottom>
        <p
          className={classnames(
            style.description,
            isLight ? 'text-color__gray--on-light' : 'text-color__gray--on-dark'
          )}
        >
          {heading.description}
        </p>
      </Fade>
      <div
        className={classnames(
          style.wrapper,
          isLight && style.isLight,
          !video && style.noVideo
        )}
      >
        <div>
          <div className={style.blockNav}>
            <button
              aria-label="prevision"
              type="button"
              className={classnames(style.blockButton, style.blockButtonPrev)}
              onClick={prevSlide}
              onKeyDown={prevSlide}
            />
            {clients.map((item, index) => (
              <div
                tabIndex={0}
                role="button"
                key={keyGenerator(index, item.client.name)}
                onKeyDown={() => activeItemToggle(index)}
                onClick={() => activeItemToggle(index)}
                className={classnames(
                  style.blockImages,
                  activeItem === index && style.blockImagesActive
                )}
              >
                {item.client.photo && (
                  <img
                    title={item.client.name}
                    loading="lazy"
                    className={style.blockPortrait}
                    src={item.client.photo.localFile.url}
                    width="100"
                    height="100"
                    alt={item.client.name}
                  />
                )}
                {item.client.country && (
                  <img
                    loading="lazy"
                    className={style.blockCountry}
                    src={
                      item.client.country.localFile.childImageSharp.resize.src
                    }
                    width={24}
                    height={24}
                    alt={item.client.country.altText}
                    title={item.client.country.title}
                  />
                )}
              </div>
            ))}
            <button
              aria-label="next"
              type="button"
              className={classnames(style.blockButton, style.blockButtonNext)}
              onClick={nextSlide}
            />
          </div>
          {clients.map((item, index) => (
            <div
              key={keyGenerator(index, item.client.title)}
              className={style.blockTextWrap}
            >
              <div
                className={classnames(
                  style.blockItem,
                  activeItem === index && style.blockItemActive
                )}
              >
                <div
                  className={classnames(
                    style.blockInfo,
                    isLight && style.blockInfoLight
                  )}
                >
                  <p className={style.blockText}>{item.client.description}</p>
                  <p className={style.blockName}>{item.client.name}</p>
                  <p className={style.blockTitle}>{item.client.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {video && (
          <div className={style.video}>
            <Modal ref={modalRef}>
              <ModalVideo videoData={video.videoSrc} />
            </Modal>

            <div
              role="button"
              tabIndex={0}
              className={style.videoPreview}
              onClick={() => modalRef.current.openModal()}
              onKeyDown={() => modalRef.current.openModal()}
            >
              <div className={style.videoCoverImg}>
                <div className={style.preview}>
                  <StaticImage
                    src="../../../../../static/images/video_prewiev.jpg"
                    alt="videoPreview"
                  />
                </div>

                <div className={style.videoBg} />
              </div>

              <div className={style.videoInfo}>
                <p className={style.videoText}>{video.clientsVideoText}</p>
                <div className={style.videoButton}>
                  {video.clientsVideoButton}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

export default MainClients
