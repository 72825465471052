import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import MainLayout from '../layouts/MainLayout'
import MainPrimary from '../components/Pages/main/MainPrimary/MainPrimary'
import MainValues from '../components/Pages/main/MainValues/MainValues'
import MainProducts from '../components/Pages/main/MainProducts/MainProducts'
import MainDevelopment from '../components/Pages/main/MainDevelopment/MainDevelopment'
import MainClients from '../components/Pages/main/MainClients/MainClients'
/* eslint-disable camelcase */
// markup
const Index = ({ data, location }) => {
  const seoData = data.wpPage.metaTags
  const { page_main } = data.wpPage
  const {
    aboutUsHeader,
    clientsHeader,
    clientItems,
    clientsVideoText,
    clientsVideoButton,
  } = page_main
  const clientsVideoData = {
    clientsVideoText,
    clientsVideoButton: clientsVideoButton.text,
    videoSrc: clientsVideoButton.embeddedHtml,
  }

  return (
    <MainLayout seoData={seoData} location={location}>
      <MainPrimary pageData={page_main} />
      <MainValues pageData={aboutUsHeader} />
      <MainProducts pageData={page_main} />
      <MainDevelopment pageData={page_main} />
      <MainClients
        clientItems={clientItems}
        video={clientsVideoData}
        heading={clientsHeader}
      />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 20 }) {
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_main {
        experienceButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        experienceHeader {
          description
          title
        }
        experienceItems {
          title
          description
          button {
            addQuery
            text
            query
            useCustomUrl
            urlCustom
            url {
              ... on WpPost {
                uri
              }
              ... on WpPage {
                uri
              }
            }
          }
        }
        companyHeader {
          description
          title
        }
        companyBtn {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        aboutUsHeader {
          description
          title
        }
        mainDescription
        mainHeading
        title
        offeringsHeader {
          description
          title
        }
        offeringItems {
          title
          showButton
          description
          iconDescription
          icons {
            title
            icon {
              sourceUrl
            }
          }
          offeringsButton {
            addQuery
            text
            query
            useCustomUrl
            urlCustom
            url {
              ... on WpPost {
                uri
              }
              ... on WpPage {
                uri
              }
            }
          }
        }
        mainHeaderButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        showcase {
          image {
            localFile {
              childImageSharp {
                resize(width: 450, quality: 90) {
                  src
                  height
                  width
                }
              }
            }
            altText
          }
        }
        headerBusinesses {
          businessButton {
            addQuery
            query
            text
            url {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                uri
              }
            }
            urlCustom
            useCustomUrl
          }
          description
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        clientsVideoText
        clientsHeader {
          description
          title
        }
        clientsVideoButton {
          embeddedHtml
          text
        }
        clientItems {
          ... on WpClient {
            client {
              title
              name
              description
              photo {
                localFile {
                  url
                }
              }
              country {
                localFile {
                  childImageSharp {
                    resize(width: 24, height: 24) {
                      src
                    }
                  }
                }
                altText
                title
              }
            }
          }
        }
      }
    }
  }
`
Index.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Index
