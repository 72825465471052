// extracted by mini-css-extract-plugin
export var accordion = "main-development-module--accordion--vETSc";
export var animateCircle = "main-development-module--animate-circle--Q6kHK";
export var body = "main-development-module--body--5pZA5";
export var bodyHeading = "main-development-module--bodyHeading--DxXva";
export var bounce = "main-development-module--bounce--FaxZn";
export var description = "main-development-module--description--3yZfg";
export var descriptionText = "main-development-module--descriptionText--ghzK2";
export var heading = "main-development-module--heading--yDu5G";
export var icon = "main-development-module--icon--Im0x4";
export var icons = "main-development-module--icons--NBJKY";
export var item = "main-development-module--item--dCr-E";
export var itemActive = "main-development-module--itemActive--Dy1MG";
export var itemData = "main-development-module--itemData--O4UR3";
export var itemDataActive = "main-development-module--itemDataActive--aGIu7";
export var ldsRing = "main-development-module--lds-ring--BmW2k";
export var list = "main-development-module--list--k3sAh";
export var mobileItemData = "main-development-module--mobileItemData--UFjji";
export var title = "main-development-module--title--jVwoq";